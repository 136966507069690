import React from "react";
import {TextField} from "@mui/material";
import {AppField} from "../fields";
import {forms, objects} from "@atttomyx/shared-utils";

const RobotFilters = (props) => {
    const { data, onChange : onSync, formProps } = props;
    const { apps, appRenderer } = formProps;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);
        modified[field] = value;

        onSync(modified);
    }

    return <div className="robot-filters">
        <div className="field">
            <TextField label="Name"
                       type="text"
                       value={forms.sanitizeValue(data.name)}
                       onChange={(event) => onChange("name", event.target.value)}
            />
        </div>
        <div className="field">
            <AppField
                apps={apps}
                appRenderer={appRenderer}
                value={data.appId}
                onChange={(appId) => onChange("appId", appId)}
            />
        </div>
    </div>
}

export default RobotFilters;
