import React, {useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography} from "@mui/material";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import {RobotForm} from "../forms";
import {internalRobotService} from "../../services";
import {forms, validation} from "@atttomyx/shared-utils";

const toData = (robot) => {
    return {
        name: robot.name,
        email: robot.email,
        password: null,
        confirm: null,
        appIds: robot.appIds,
        valid: robot.name && robot.email && validation.validEmail(robot.email),
    }
};

const RobotDialog = (props) => {
    const { snackbar, apps, appRenderer, robot, onCancel, onSave, showFields, showPassword } = props;
    const [ data, setData ] = useState(toData(robot));
    const [ saving, setSaving ] = useState(false);

    const changePassword = showPassword && !showFields;

    const submitForm = () => {
        setSaving(true);

        const success = (saved) => {
            snackbar.setSuccess(saved.name + " saved");
            setSaving(false);
            onSave(saved);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        };

        const modified = {
            name: data.name,
            email: data.email,
            password: showPassword ? data.password : null,
            appIds: data.appIds,
        };

        internalRobotService.saveRobot(modified, success, failure);
    }

    return <Dialog
        open={true}
        aria-labelledby="robot-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            {changePassword ? "Change Password" : "Robot"}
        </ClosableDialogTitle>
        <DialogContent className="robot-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <>
                    {changePassword ?
                        <Typography>
                            {data.name}
                        </Typography> : null}
                    <RobotForm
                        apps={apps}
                        appRenderer={appRenderer}
                        data={data}
                        onChange={setData}
                        showFields={showFields}
                        showPassword={showPassword}
                    />
                </>}
        </DialogContent>
        {!saving ? <DialogActions>
            <Button color="primary"
                    disabled={!data.valid || !forms.differ(toData(robot), data)}
                    onClick={submitForm}>
                Save
            </Button>
        </DialogActions> : null}
    </Dialog>
}

export default RobotDialog;
