import React, {useState} from "react";
import {CircularProgress, IconButton, TextField} from "@mui/material";
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";
import {AppField} from "../fields";
import {BannerAd} from "../misc";
import {forms, objects, validation} from "@atttomyx/shared-utils";
import "./adForm.css";

const AdForm = (props) => {
    const { snackbar, data, apps, appRenderer, onChange : onSync, imageService } = props;
    const [ uploading, setUploading ] = useState(false);

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;

        modified.valid = modified.appId && modified.imageUrl && modified.clickUrl
            && validation.validUrl(modified.clickUrl);

        onSync(modified);
    }

    const uploadFile = (event) => {
        const file = event.target.files[0];

        setUploading(true);

        const success = (logoUrl) => {
            setUploading(false);
            onChange("imageUrl", logoUrl);
        };

        const failure = (err) => {
            setUploading(false);
            snackbar.setError(err);
        };

        imageService.uploadImage(data.appId, file, success, failure);
    }

    const removeFile = () => {
        onChange("imageUrl", null);
    }

    return <div className="ad-form">
        <div className="field">
            <AppField
                apps={apps}
                appRenderer={appRenderer}
                required={true}
                value={data.appId}
                onChange={(appId) => onChange("appId", appId)}
            />
        </div>
        <div className="field">
            <TextField label="Click URL" required={true}
                       type="url"
                       value={forms.sanitizeValue(data.clickUrl)}
                       error={!validation.validUrl(data.clickUrl)}
                       onChange={(event) => onChange("clickUrl", event.target.value)}
            />
        </div>
        {data.appId ? <div className="field image">
            {uploading ?
                <CircularProgress size="40px"/> :
                <>
                    <BannerAd imageUrl={data.imageUrl} />
                    <IconButton color="secondary" title="Delete image"
                                disabled={objects.nullOrUndefined(data.imageUrl)}
                                onClick={removeFile}>
                        <DeleteIcon/>
                    </IconButton>
                    <input hidden
                           accept="image/*"
                           id="ad-file"
                           type="file"
                           onChange={uploadFile}
                    />
                    <label htmlFor="ad-file">
                        <IconButton color="primary" title="Upload image" component="span">
                            <EditIcon/>
                        </IconButton>
                    </label>
                </>}
        </div> : null}
    </div>
}

export default AdForm;
