import axios from 'axios';
import {accountUtils} from "../../utils";
import {CLOUDINARY_ID} from "../../constants";

export const listAccounts = (cursor, limit, success, failure) => {
    let url = "/api/v1/internal/account/list?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
        .then(response => {
            const json = response.data;

            json.accounts.forEach((account) => {
                accountUtils.sanitizeAccount(account);
            });

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const addAccount = (account, success, failure) => {
    const url = "/api/v1/internal/account/";

    axios.post(url, {
        appId: account.appId,
        billingId: account.billingId,
        name: account.name,
        website: account.website,
        branding: accountUtils.standardizeBranding(account.branding),
        disabled: account.disabled,
        allowJoin: account.allowJoin,
    })
        .then(response => {
            const account = response.data;

            accountUtils.sanitizeAccount(account);
            success(account);
        })
        .catch(err => {
            failure(err);
        });
};

export const updateAccount = (accountId, account, success, failure) => {
    const url = `/api/v1/internal/account/${accountId}/`;

    axios.put(url, {
        appId: account.appId,
        billingId: account.billingId,
        name: account.name,
        website: account.website,
        branding: accountUtils.standardizeBranding(account.branding),
        disabled: account.disabled,
        allowJoin: account.allowJoin,
    })
        .then(response => {
            const account = response.data;

            accountUtils.sanitizeAccount(account);
            success(account);
        })
        .catch(err => {
            failure(err);
        });
};

export const deleteAccount = (accountId, success, failure) => {
    const url = `/api/v1/internal/account/${accountId}/`;

    axios.delete(url)
        .then(response => {
            success(accountId);
        })
        .catch(err => {
            failure(err);
        });
};

export const uploadImage = (accountId, logo, success, failure) => {
    const url = `https://api.cloudinary.com/v1_1/${CLOUDINARY_ID}/upload`;

    const data = new FormData();
    data.append("upload_preset", "account_image");
    data.append("file", logo);
    data.append("tags", accountId);

    // need to skip the request interceptors (don't want to include the JWT)
    const instance = axios.create();

    instance.post(url, data, {
        headers: {
            "X-Requested-With": "XMLHttpRequest",
        },
    })
    .then(response => {
        success(response.data.secure_url);
    })
    .catch(err => {
        failure(err);
    });
};
