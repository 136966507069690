import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {useFiltered} from "@atttomyx/shared-hooks";
import {IconButton, Typography} from "@mui/material";
import {Delete as DeleteIcon, Edit as EditIcon, Rocket as ReleaseIcon} from "@mui/icons-material";
import {Cards, ConfirmDeleteDialog, FiltersAccordion, FloatingAddButton} from "@atttomyx/react-components";
import {AppFilters} from "../filters";
import {AppCard} from "../cards";
import {ReleaseDialog} from "../dialogs";
import {internalAppService} from "../../services";
import {router} from "@atttomyx/react-utils";
import {PAGE_APP} from "../../constants";

const AppsPage = (props) => {
    const { history, snackbar, dimensions, apps, filters, onSave, onDelete } = props;
    const [ app, setApp ] = useState(null);
    const [ showRelease, setShowRelease ] = useState(false);
    const [ showDelete, setShowDelete ] = useState(false);
    const [ showFilters, setShowFilters ] = useState(false);
    const filtered = useFiltered(apps, filters);

    useEffect(() => {
        if (!app) {
            setShowRelease(false);
            setShowDelete(false);
        }
    }, [app]);

    return <div className="apps-page">
        <Typography variant="h5" paragraph={true}>
            Apps
        </Typography>
        <div className="field">
            <FiltersAccordion
                filters={filters}
                form={AppFilters}
                open={showFilters}
                onOpen={() => setShowFilters(true)}
                onClose={() => setShowFilters(false)}
            />
        </div>
        <Cards
            items={filtered}
            paging={filters.paging}
            dimensions={dimensions}
            renderer={(app) =>
                <AppCard key={app.id} app={app} filters={filters}>
                    <IconButton color="secondary" title="Delete" className="delete"
                                onClick={() => {
                                    setApp(app);
                                    setShowDelete(true);
                                }}>
                        <DeleteIcon/>
                    </IconButton>
                    <IconButton color="primary" title="Release"
                                onClick={() => {
                                    setApp(app);
                                    setShowRelease(true);
                                }}>
                        <ReleaseIcon/>
                    </IconButton>
                    <IconButton color="primary" title="Edit"
                                onClick={() => router.redirectTo(history, PAGE_APP + "/" + app.id)}>
                        <EditIcon/>
                    </IconButton>
                </AppCard>}
        />
        <FloatingAddButton
            title="Add app"
            position={!dimensions.landscape || !dimensions.short ? "higher" : undefined}
            center={dimensions.landscape && !dimensions.short}
            onClick={() => router.redirectTo(history, PAGE_APP)}
        />
        {showRelease && app ? <ReleaseDialog
            snackbar={snackbar}
            app={app}
            onCancel={() => {
                setShowRelease(false);
                setApp(null);
            }}
            onSave={(saved) => {
                setShowRelease(false);
                setApp(null);
                onSave(saved);
            }}
        /> : null}
        {showDelete && app ? <ConfirmDeleteDialog
            snackbar={snackbar}
            title={app.title}
            onCancel={() => {
                setShowDelete(false);
                setApp(null);
            }}
            onDelete={(appId) => {
                setShowDelete(false);
                setApp(null);
                onDelete(appId);
            }}
            deleter={(success, failure) => internalAppService.deleteApp(app.id, success, failure)}
        /> : null}
    </div>
}

export default withRouter(AppsPage);
