import React from "react";
import {TextField} from "@mui/material";
import {AppField} from "../fields";
import {forms, objects, strings, validation} from "@atttomyx/shared-utils";

const RobotForm = (props) => {
    const { apps, appRenderer, data, onChange : onSync, showFields, showPassword } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;

        modified.valid = modified.name && modified.email
            && modified.appIds && modified.appIds.length > 0
            && validation.validEmail(modified.email)
            && (!showPassword || (modified.password && modified.confirm && modified.password === modified.confirm));

        onSync(modified);
    }

    return <div className="robot-form">
        {showFields ? <div className="field top-margin">
            <TextField label="Name" required={true}
                       type="text"
                       value={forms.sanitizeValue(data.name)}
                       onChange={(event) => onChange("name", event.target.value)}
            />
        </div> : null}
        {showFields ? <div className="field">
            <TextField label="Email" required={true}
                       type="email"
                       value={forms.sanitizeValue(data.email)}
                       error={!validation.validEmail(data.email)}
                       onChange={(event) => onChange("email", event.target.value)}
                       inputProps={{
                           autoComplete: "off",
                       }}
            />
        </div> : null}
        {showPassword ? <div className={"field" + (!showFields ? " top-margin" : "")}>
            <TextField label="Password" required={true}
                       type="password"
                       value={forms.sanitizeValue(data.password)}
                       onChange={(event) => onChange("password", event.target.value)}
                       inputProps={{
                           autoComplete: "new-password",
                       }}
            />
        </div> : null}
        {showPassword ? <div className="field">
            <TextField label="Password (again)" required={true}
                       type="password"
                       value={forms.sanitizeValue(data.confirm)}
                       error={strings.isNotBlank(data.confirm) && strings.differ(data.password, data.confirm)}
                       onChange={(event) => onChange("confirm", event.target.value)}
                       inputProps={{
                           autoComplete: "new-password",
                       }}
            />
        </div> : null}
        {showFields ? <div className="field">
            <AppField
                apps={apps}
                appRenderer={appRenderer}
                required={true}
                multiple={true}
                value={data.appIds}
                onChange={(appIds) => onChange("appIds", appIds)}
            />
        </div> : null}
    </div>
}

export default RobotForm;
