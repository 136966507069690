import React, {useEffect, useState} from "react";
import {Prompt} from "react-router-dom";
import {Button, CircularProgress, IconButton, Typography} from "@mui/material";
import {NavigateBefore as PrevIcon, NavigateNext as NextIcon} from "@mui/icons-material";
import {BrandingForm, JoinCode, VerticalDivider} from "@atttomyx/react-components";
import {AccountForm} from "../forms";
import {internalAccountService} from "../../services";
import {arrays, forms, strings} from "@atttomyx/shared-utils";
import {verbiage} from "@atttomyx/shared-constants";
import "./editAccountPage.css";

const toData = (account) => {
    return {
        appId: account.appId,
        billingId: account.billingId,
        disabled: account.disabled,
        allowJoin: strings.isNotBlank(account.code),
        valid: true,
    }
};

const toBranding = (account) => {
    const branding = account.branding || {};

    return {
        name: account.name,
        website: account.website,
        logoUrl: branding.logoUrl,
        logoContainsName: branding.logoContainsName,
        primary: branding.primary,
        secondary: branding.secondary,
        valid: true,
    }
};

const EditAccountPage = (props) => {
    const {snackbar, match, apps, appRenderer, billingRenderer, accounts, filters,
        onSaveAccount, onSaveBilling, onPrev, onNext} = props;
    const [account, setAccount] = useState({});
    const [data, setData] = useState(toData({}));
    const [branding, setBranding] = useState(toBranding({}));
    const [saving, setSaving] = useState(false);
    const [ prevId, setPrevId ] = useState(null);
    const [ nextId, setNextId ] = useState(null);

    const loadAccount = (accountId) => {
        const previousId = account.id;
        const filtered = filters.filter(accounts);
        const found = arrays.findEntity(filtered, accountId) || {};
        const ids = arrays.getPrevNextIds(filtered, found);

        setAccount(found);
        setPrevId(ids.prevId);
        setNextId(ids.nextId);

        if (previousId && previousId !== accountId && found.id === accountId) {
            snackbar.setInfo("Loaded " + found.name);
        }
    };

    const redirectToPrev = () => {
        onPrev(prevId);
        loadAccount(prevId);
    }

    const redirectToNext = () => {
        onNext(nextId);
        loadAccount(nextId);
    }

    useEffect(() => {
        const accountId = match.params.id;

        loadAccount(accountId);
    }, []);

    useEffect(() => {
        setData(toData(account));
        setBranding(toBranding(account));
    }, [account]);

    const resetForm = () => {
        setData(toData(account));
        setBranding(toBranding(account));
    }

    const submitForm = () => {
        setSaving(true);

        const modified = {
            appId: data.appId,
            billingId: data.billingId,
            name: branding.name,
            website: branding.website,
            branding: {
                logoUrl: branding.logoUrl,
                logoContainsName: branding.logoContainsName,
                primary: branding.primary,
                secondary: branding.secondary,
            },
            disabled: data.disabled,
            allowJoin: data.allowJoin,
        };

        const success = (account) => {
            setAccount(account);
            setSaving(false);

            snackbar.setSuccess("Account saved");
            onSaveAccount(account);
        };

        const failure = (err) => {
            setSaving(false);
            snackbar.setError(err);
        };

        internalAccountService.updateAccount(account.id, modified, success, failure);
    }

    const valid = data.valid && branding.valid;
    const modified = forms.differ(toData(account), data)
        || forms.differ(toBranding(account), branding);

    return saving ?
        <div className="edit-account-page">
            <CircularProgress size="80px"/>
        </div> :
        <div className="edit-account-page">
            <div className="cards">
                <div className="card">
                    <BrandingForm
                        accountId={account.id}
                        data={branding}
                        onChange={setBranding}
                        showImage={true}
                        imageService={internalAccountService}
                    />
                </div>
                <div className="card">
                    <Typography variant="h5" paragraph={true}>
                        General
                    </Typography>
                    <AccountForm
                        snackbar={snackbar}
                        apps={apps}
                        appRenderer={appRenderer}
                        billingRenderer={billingRenderer}
                        data={data}
                        onChange={setData}
                        onSaveBilling={onSaveBilling}
                    />
                </div>
            </div>
            <div className="actions">
                <IconButton color="secondary" title="Previous account"
                            disabled={!prevId}
                            onClick={redirectToPrev}>
                    <PrevIcon/>
                </IconButton>
                <IconButton color="secondary" title="Next account"
                            disabled={!nextId}
                            onClick={redirectToNext}>
                    <NextIcon/>
                </IconButton>
                <VerticalDivider/>
                <Button color="secondary" variant="text"
                        disabled={!modified}
                        onClick={resetForm}>
                    Undo
                </Button>
                <Button color="primary" size="large"
                        disabled={!valid || !modified}
                        onClick={submitForm}>
                    Save
                </Button>
            </div>
            <Prompt when={modified} message={verbiage.UNSAVED_CHANGES}/>
            <JoinCode allowJoin={data.allowJoin} code={account.code}/>
        </div>
}

export default EditAccountPage;
