import React, {useCallback, useState} from 'react';
import {Card, CircularProgress} from "@mui/material";
import {useDropzone} from "react-dropzone";
import {internalCategoryService} from "../../services";
import "./addImageCard.css";

const AddImageCard = (props) => {
    const { snackbar, categoryId, onSave } = props;
    const [ saving, setSaving ] = useState(false);

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length === 1) {
            const file = acceptedFiles[0];

            setSaving(true);

            const success = (uuid, imageUrl) => {
                onSave({
                    uuid: uuid,
                    imageUrl: imageUrl,
                });
            };

            const failure = (err) => {
                setSaving(false);
                snackbar.setError(err);
            };

            internalCategoryService.uploadImage(categoryId, file, success, failure);
        }
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop: onDrop,
        maxFiles: 1,
    });

    return <Card className="add-image-card" raised={true}>
        {saving ? <CircularProgress size="40px"/> :
        <div {...getRootProps()} className="dnd-file">
            <input {...getInputProps()} />
            {isDragActive ?
                <p>Drop the image here</p> :
                <p>Drag & drop an image here, or click to select</p>}
        </div>}
    </Card>
}

export default AddImageCard;
