import React, {useMemo, useState} from "react";
import {Chart} from "react-google-charts";
import {arrays, objects, sorting} from "@atttomyx/shared-utils";
import {FiltersAccordion} from "@atttomyx/react-components";
import {AggregateFilters} from "../filters";
import {USERS_NEW, USERS_RETURNING} from "../../constants";
import "./aggregatePage.css";

const COLUMN_WEEK = "Week";

const isYear = (yearWeek, year) => {
    return objects.nullOrUndefined(year) || year === yearWeek.substring(0, 4);
};

const AggregatePage = (props) => {
    const { dimensions, apps, appRenderer, aggregates, filters } = props;
    const [ showFilters, setShowFilters ] = useState(false);

    const width = useMemo(() => {
        return dimensions.landscape
            ? dimensions.width - (dimensions.short ? 90 : 55)
            : dimensions.width - 40;
    }, [dimensions]);

    const height = useMemo(() => {
        return dimensions.landscape && dimensions.short
            ? dimensions.height - 120
            : dimensions.height - 200;
    }, [dimensions]);

    const options = useMemo(() => {
        return {
            title: "Users",
            chartArea: {
                top: 50,
                bottom: dimensions.landscape ? 50 : 100,
                left: 50,
                right: dimensions.landscape ? dimensions.short ? 150 : 200 : 50,
            },
            legend: {
                position: dimensions.landscape ? "right" : "bottom",
            },
        }
    }, [dimensions]);

    const data = useMemo(() => {
        const titleToId = {};
        const columns = [];

        apps
        .filter(app => filters.data.appIds == null || arrays.contains(filters.data.appIds, app.id))
        .forEach(app => {
            titleToId[app.title] = app.id;
            arrays.addTo(columns, app.title);
        });

        columns.sort(sorting.alphabetize);
        arrays.prependTo(columns, COLUMN_WEEK);

        const data = [columns];

        aggregates
        .filter(aggregate => isYear(aggregate.yearWeek, filters.data.year))
        .forEach(aggregate => {
            const idToUsers = {};
            const row = [aggregate.yearWeek];

            aggregate.counts.forEach(item => {
                const newUsers = objects.defaultIfNullOrUndefined(item.newUsers, 0);
                const returningUsers = objects.defaultIfNullOrUndefined(item.returningUsers, 0);
                let users;

                switch (filters.data.users) {
                    case USERS_NEW:
                        users = newUsers;
                        break;
                    case USERS_RETURNING:
                        users = returningUsers;
                        break;
                    default:
                        users = newUsers + returningUsers;
                        break;
                }

                idToUsers[item.appId] = users;
            });

            columns.filter(column => column !== COLUMN_WEEK).forEach(column => {
                const id = titleToId[column];

                if (id) {
                    const users = idToUsers[id];

                    if (objects.notNullOrUndefined(users)) {
                        arrays.addTo(row, users);

                    } else {
                        arrays.addTo(row, 0);
                    }
                }
            });

            arrays.addTo(data, row);
        });

        if (data.length === 1) {
            const row = ["No results..."];

            columns.filter(column => column !== COLUMN_WEEK).forEach(column => {
                arrays.addTo(row, 0);
            });

            arrays.addTo(data, row);
        }

        return data;
    }, [apps, filters.data]);

    return <div className="aggregate-page">
        <FiltersAccordion
            filters={filters}
            form={AggregateFilters}
            formProps={{
                apps: apps,
                appRenderer: appRenderer,
            }}
            open={showFilters}
            onOpen={() => setShowFilters(true)}
            onClose={() => setShowFilters(false)}
        />
        <Chart
            chartType="ColumnChart"
            width={width}
            height={height}
            options={options}
            data={data}
        />
    </div>
}

export default AggregatePage;
