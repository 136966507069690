import React from "react";
import {MenuItem, TextField} from "@mui/material";
import {AppField, BillingField} from "../fields";
import {forms, objects} from "@atttomyx/shared-utils";
import {accounts} from "@atttomyx/shared-constants";

const AccountFilters = (props) => {
    const { data, onChange : onSync, formProps } = props;
    const { apps, appRenderer, billingRenderer } = formProps;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);
        modified[field] = value;

        onSync(modified);
    }

    return <div className="account-filters">
        <div className="field">
            <TextField label="Name"
                       type="text"
                       value={forms.sanitizeValue(data.name)}
                       onChange={(event) => onChange("name", event.target.value)}
            />
        </div>
        <div className="field">
            <AppField
                apps={apps}
                appRenderer={appRenderer}
                value={data.appId}
                onChange={(appId) => onChange("appId", appId)}
            />
        </div>
        <div className="field">
            <BillingField
                billingRenderer={billingRenderer}
                value={data.billingId}
                onChange={(billingId) => onChange("billingId", billingId)}
            />
        </div>
        <div className="field">
            <TextField label="Status"
                       select={true}
                       value={forms.sanitizeValue(data.status)}
                       onChange={(event) => onChange("status", event.target.value)}
            >
                <MenuItem value="">&nbsp;</MenuItem>
                <MenuItem value={accounts.STATUS_ACTIVE}>Active</MenuItem>
                <MenuItem value={accounts.STATUS_DISABLED}>Disabled</MenuItem>
            </TextField>
        </div>
    </div>
}

export default AccountFilters;
