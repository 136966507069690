import React, {useEffect, useState} from "react";
import {Button, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import {AddCircle as AddIcon, Delete as RemoveIcon} from "@mui/icons-material";
import {UserAvatar} from "@atttomyx/react-components";
import {AddOwnerDialog, AddUserDialog} from "../dialogs";
import {arrays} from "@atttomyx/shared-utils";
import {misc} from "@atttomyx/shared-constants";
import "./ownersForm.css";

const OwnersForm = (props) => {
    const { snackbar, accounts, accountRenderer, users, ownerIds, onChange, onSaveUser } = props;
    const [ usersById, setUsersById ] = useState({});
    const [ showAddOwner, setShowAddOwner ] = useState(false);
    const [ showAddUser, setShowAddUser ] = useState(false);

    useEffect(() => {
        setUsersById(arrays.getIdToEntity(users));
    }, [users]);

    const removeOwnerId = (ownerId) => {
        const modified = [].concat(ownerIds);

        arrays.removeFrom(modified, ownerId);

        onChange(modified);
    };

    const addOwnerId = (ownerId) => {
        if (!arrays.contains(ownerIds, ownerId)) {
            const modified = [].concat(ownerIds);

            arrays.addTo(modified, ownerId);

            onChange(modified);
        }
    };

    return <div className="owners-form">
        <Typography variant="h5">
            Owners
        </Typography>
        <Table size="small" cellPadding={0} cellSpacing={0} className="striped owners">
            <TableBody>
                {ownerIds.map(ownerId =>
                    <TableRow key={ownerId}>
                        <TableCell width="90%">
                            {usersById[ownerId] ?
                                <UserAvatar user={usersById[ownerId]} mode="right" size="small"/> : misc.UNKNOWN}
                        </TableCell>
                        <TableCell width="10%">
                            <IconButton color="secondary" title={"Remove " + ownerId}
                                        onClick={() => removeOwnerId(ownerId)}>
                                <RemoveIcon/>
                            </IconButton>
                        </TableCell>
                    </TableRow>)}
            </TableBody>
        </Table>
        <PopupState variant="popover" popupId="add">
            {(popupState) => (
                <>
                    <Button color="primary" variant="text" className="add-owner"
                            {...bindTrigger(popupState)}>
                        <AddIcon/> Add Owner
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={() => {
                            popupState.close();
                            setShowAddOwner(true);
                        }}>Select existing user</MenuItem>
                        <MenuItem onClick={() => {
                            popupState.close();
                            setShowAddUser(true);
                        }}>Create new user</MenuItem>
                    </Menu>
                </>
            )}
        </PopupState>
        {showAddOwner ?
            <AddOwnerDialog
                accounts={accounts}
                accountRenderer={accountRenderer}
                users={users}
                onCancel={() => setShowAddOwner(false)}
                onAdd={(userId) => {
                    setShowAddOwner(false);
                    addOwnerId(userId);
                }}
            /> : null}
        {showAddUser ?
            <AddUserDialog
                snackbar={snackbar}
                onCancel={() => setShowAddUser(false)}
                onSave={(user) => {
                    setShowAddUser(false);
                    onSaveUser(user);
                    addOwnerId(user.id);
                }}
            /> : null}
    </div>
};

export default OwnersForm;
