import React from "react";
import {TextField} from "@mui/material";
import {AppTypeField} from "../fields";
import {forms, objects} from "@atttomyx/shared-utils";

const AppGeneralFields = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = true;

        onSync(modified);
    }

    return <div className="app-general-form">
        <div className="field">
            <AppTypeField
                required={true}
                value={data.type}
                onChange={(type) => onChange("type", type)}
            />
        </div>
        <div className="field">
            <TextField label="Welcome new admin"
                       type="text"
                       multiline={true}
                       value={forms.sanitizeValue(data.welcomeAdmin)}
                       onChange={(event) => onChange("welcomeAdmin", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Welcome new user"
                       type="text"
                       multiline={true}
                       value={forms.sanitizeValue(data.welcomeUser)}
                       onChange={(event) => onChange("welcomeUser", event.target.value)}
            />
        </div>
    </div>
}

export default AppGeneralFields;
