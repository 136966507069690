import React, {useEffect, useState} from "react";
import {Prompt} from "react-router-dom";
import {Button, CircularProgress, Divider, IconButton} from "@mui/material";
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    NavigateBefore as PrevIcon,
    NavigateNext as NextIcon
} from "@mui/icons-material";
import {usePaging} from "@atttomyx/shared-hooks";
import {
    Cards,
    ConfirmDeleteDialog,
    FloatingAddButton,
    TabBar,
    TabPanel,
    VerticalDivider
} from "@atttomyx/react-components";
import {BillingForm, OwnersForm} from "../forms";
import {ChargeCard, PaymentCard} from "../cards";
import {ChargeDialog} from "../dialogs";
import {internalBillingService, internalChargeService} from "../../services";
import {arrays, forms} from "@atttomyx/shared-utils";
import {verbiage} from "@atttomyx/shared-constants";
import {BILLING_STATUS_ACTIVE} from "../../constants";
import "./editBillingPage.css";

const toData = (billing) => {
    return {
        name: billing.name,
        environment: billing.environment,
        status: billing.environment ? billing.status : BILLING_STATUS_ACTIVE,
        hidden: billing.hidden,
        valid: billing.name && billing.status,
    }
};

const toOwnerIds = (billing) => {
    return billing.userIds || [];
};

const EditBillingPage = (props) => {
    const {snackbar, dimensions, match, accounts, accountRenderer, users, billings, filters, charges, payments,
        onSaveBilling, onSaveCharge, onSavePayment, onDeleteCharge, onSaveUser, onPrev, onNext} = props;
    const [billing, setBilling] = useState({});
    const [data, setData] = useState(toData({}));
    const [ownerIds, setOwnerIds ] = useState(toOwnerIds({}));
    const [filteredCharges, setFilteredCharges] = useState([]);
    const [filteredPayments, setFilteredPayments] = useState([]);
    const [saving, setSaving] = useState(false);
    const [ prevId, setPrevId ] = useState(null);
    const [ nextId, setNextId ] = useState(null);
    const [ tab, setTab ] = useState(0);
    const [ showCharge, setShowCharge ] = useState(false);
    const [ showDelete, setShowDelete ] = useState(false);
    const [ charge, setCharge ] = useState(null);
    const chargePaging = usePaging();
    const paymentPaging = usePaging();

    const loadBilling = (billingId) => {
        const previousId = billing.id;
        const filtered = filters.filter(billings);
        const found = arrays.findEntity(filtered, billingId) || {};
        const ids = arrays.getPrevNextIds(filtered, found);

        setBilling(found);
        setPrevId(ids.prevId);
        setNextId(ids.nextId);

        if (previousId && previousId !== billingId && found.id === billingId) {
            snackbar.setInfo("Loaded " + found.name);
        }
    };

    const redirectToPrev = () => {
        onPrev(prevId);
        loadBilling(prevId);
    }

    const redirectToNext = () => {
        onNext(nextId);
        loadBilling(nextId);
    }

    useEffect(() => {
        const billingId = match.params.id;

        loadBilling(billingId);
    }, []);

    useEffect(() => {
        setData(toData(billing));
        setOwnerIds(toOwnerIds(billing));
    }, [billing]);

    useEffect(() => {
        const chargesByBillingId = arrays.groupByField(charges, "billingId");
        const paymentsByBillingId = arrays.groupByField(payments, "billingId");

        setFilteredCharges(chargesByBillingId[billing.id] || []);
        setFilteredPayments(paymentsByBillingId[billing.id] || []);
    }, [billing, charges, payments]);

    const resetForm = () => {
        setData(toData(billing));
        setOwnerIds(toOwnerIds(billing));
    }

    const submitForm = () => {
        setSaving(true);

        const modified = {
            name: data.name,
            environment: data.environment,
            status: data.status,
            hidden: data.hidden,
            userIds: ownerIds,
        };

        const success = (billing) => {
            setBilling(billing);
            setSaving(false);

            snackbar.setSuccess("Billing saved");
            onSaveBilling(billing);
        };

        const failure = (err) => {
            setSaving(false);
            snackbar.setError(err);
        };

        internalBillingService.saveBilling(billing.id, modified, success, failure);
    }

    const valid = data.valid;
    const modified = forms.differ(toData(billing), data) || forms.differ(toOwnerIds(billing), ownerIds);

    return saving ?
        <div className="edit-billing-page">
            <CircularProgress size="80px"/>
        </div> :
        <div className="edit-billing-page">
            <div className="cards">
                <div className="card">
                    <BillingForm
                        data={data}
                        onChange={setData}
                    />
                </div>
                <div className="card">
                    <OwnersForm
                        snackbar={snackbar}
                        accounts={accounts}
                        accountRenderer={accountRenderer}
                        users={users}
                        ownerIds={ownerIds}
                        onChange={setOwnerIds}
                        onSaveUser={onSaveUser}
                    />
                </div>
            </div>
            <div className="actions">
                <IconButton color="secondary" title="Previous billing"
                            disabled={!prevId}
                            onClick={redirectToPrev}>
                    <PrevIcon/>
                </IconButton>
                <IconButton color="secondary" title="Next billing"
                            disabled={!nextId}
                            onClick={redirectToNext}>
                    <NextIcon/>
                </IconButton>
                <VerticalDivider/>
                <Button color="secondary" variant="text"
                        disabled={!modified}
                        onClick={resetForm}>
                    Undo
                </Button>
                <Button color="primary" size="large"
                        disabled={!valid || !modified}
                        onClick={submitForm}>
                    Save
                </Button>
            </div>
            <Divider/>
            <TabBar
                tabs={["Upcoming", "History"]}
                value={tab}
                onChange={setTab}
            />
            <TabPanel value={tab} index={0}>
                <Cards
                    label="charge"
                    items={filteredCharges}
                    paging={chargePaging}
                    dimensions={dimensions}
                    renderer={(charge) =>
                        <ChargeCard key={charge.id} charge={charge}>
                            <IconButton color="secondary" title="Delete" className="delete"
                                        onClick={() => {
                                            setShowDelete(true);
                                            setCharge(charge);
                                        }}>
                                <DeleteIcon/>
                            </IconButton>
                            <IconButton color="primary" title="Edit"
                                        onClick={() => {
                                            setShowCharge(true);
                                            setCharge(charge);
                                        }}>
                                <EditIcon/>
                            </IconButton>
                        </ChargeCard>}
                />
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <Cards
                    label="payment"
                    items={filteredPayments}
                    paging={paymentPaging}
                    dimensions={dimensions}
                    renderer={(payment) =>
                        <PaymentCard key={payment.id} payment={payment}/>}
                />
            </TabPanel>
            {tab === 0
                ? <FloatingAddButton
                    title="Add Charge"
                    position={!dimensions.landscape || !dimensions.short ? "higher" : undefined}
                    center={dimensions.landscape && !dimensions.short}
                    onClick={() => {
                        setShowCharge(true);
                        setCharge({});
                    }}/>
                : null}
            {showCharge && charge ? <ChargeDialog
                snackbar={snackbar}
                billingId={billing.id}
                charge={charge}
                onCancel={() => {
                    setShowCharge(false);
                    setCharge(null);
                }}
                onSave={(saved, payment) => {
                    setShowCharge(false);
                    setCharge(null);

                    if (payment) {
                        onSavePayment(payment);
                    }

                    if (!payment || !payment.success) {
                        onSaveCharge(saved);
                    }
                }}
            /> : null}
            {showDelete && charge ? <ConfirmDeleteDialog
                snackbar={snackbar}
                title={charge.reason}
                onCancel={() => {
                    setShowDelete(false);
                    setCharge(null);
                }}
                onDelete={(chargeId) => {
                    setShowDelete(false);
                    setCharge(null);
                    onDeleteCharge(chargeId);
                }}
                deleter={(success, failure) => internalChargeService.deleteCharge(charge.id, success, failure)}
            /> : null}
            <Prompt when={modified} message={verbiage.UNSAVED_CHANGES}/>
        </div>
}

export default EditBillingPage;
