import React from 'react';
import {Card, CardActions, CardContent} from "@mui/material";
import {CardData, CardTitle, Logo} from "@atttomyx/react-components";
import {datetime} from "@atttomyx/shared-utils";
import {appUtils} from "../../utils";

const AppCard = (props) => {
    const { app, filters, children } = props;

    return <Card className="app-card" raised={true}>
        <CardContent>
            <CardTitle>
                <Logo logoUrl={app.logoUrl} alt={app.title} clickable={true}/>
                {app.title}
            </CardTitle>
            <CardData
                filters={filters}
                data={app}
                keyToMetadata={{
                    version: {
                        title: "Version",
                        formatter: appUtils.renderVersion,
                    },
                    type: {
                        title: "Type",
                        formatter: appUtils.renderType,
                    },
                    created: {
                        title: "Created",
                        formatter: datetime.getFullDateAndTime,
                    },
                    updated: {
                        title: "Updated",
                        formatter: datetime.getFullDateAndTime,
                    },
                }}
            />
        </CardContent>
        <CardActions disableSpacing={true}>
            {children}
        </CardActions>
    </Card>
}

export default AppCard;
