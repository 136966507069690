import {accounts, filters} from "@atttomyx/shared-constants";
import {datetime} from "@atttomyx/shared-utils";

export const APP_FILTERS = {
    title: {
        type: filters.TYPE_STRING,
        label: "name",
    },
    type: {
        type: filters.TYPE_STRING,
    },
};

export const getAccountFilters = (appRenderer, billingRenderer) => {
    return {
        name: {
            type: filters.TYPE_STRING,
        },
        appId: {
            type: filters.TYPE_STRING,
            label: "app",
            formatter: (value) => appRenderer.render(value),
        },
        billingId: {
            type: filters.TYPE_STRING,
            label: "billing",
            formatter: (value) => billingRenderer.render(value),
        },
        status: {
            type: filters.TYPE_STRING,
        },
    }
};

export const ACCOUNT_FILTERS_DATA = {
    name: null,
    appId: null,
    billingId: null,
    status: accounts.STATUS_ACTIVE,
};

export const USER_FILTERS = {
    id: {
        type: filters.TYPE_STRING,
    },
    imageUrl: {
        type: filters.TYPE_BOOLEAN,
        label: "image",
    },
    firstName: {
        type: filters.TYPE_STRING,
    },
    lastName: {
        type: filters.TYPE_STRING,
    },
    alias: {
        type: filters.TYPE_STRING,
    },
    email: {
        type: filters.TYPE_STRING,
    },
    phone: {
        type: filters.TYPE_STRING,
    },
    accounts: {
        type: filters.TYPE_COUNT,
        getter: (item) => item.accountIds.length,
    },
    installations: {
        type: filters.TYPE_COUNT,
        getter: (item) => item.installationIds.length,
    },
    role: {
        type: filters.TYPE_ARRAY,
        getter: (item) => item.roles,
    },
};

export const getRobotFilters = (appRenderer) => {
    return {
        name: {
            type: filters.TYPE_STRING,
        },
        appId: {
            type: filters.TYPE_ARRAY,
            label: "app",
            getter: (item) => item.appIds,
            formatter: (value) => appRenderer.render(value),
        },
    }
};

export const getAdFilters = (appRenderer) => {
    return {
        appId: {
            type: filters.TYPE_ARRAY,
            label: "app",
            formatter: (value) => appRenderer.render(value),
        },
    }
};

export const CATEGORY_FILTERS = {
    title: {
        type: filters.TYPE_STRING,
    },
    status: {
        type: filters.TYPE_STRING,
    },
};

export const BILLING_FILTERS = {
    name: {
        type: filters.TYPE_STRING,
    },
    environment: {
        type: filters.TYPE_BOOLEAN,
    },
    hidden: {
        type: filters.TYPE_BOOLEAN,
    },
};

export const BILLING_FILTERS_DATA = {
    name: null,
    environment: null,
    hidden: filters.FALSE,
};

export const getAggregateFilters = (appRenderer) => {
    return {
        year: {
            type: filters.TYPE_STRING,
            getter: (item) => item.yearWeek.substring(0, 4),
        },
        users: {
            type: filters.TYPE_STRING,
        },
        appIds: {
            type: filters.TYPE_ARRAY,
            label: "apps",
            formatter: (value) => appRenderer.render(value),
        },
    }
};

export const getAggregateData = () => {
    return {
        year: datetime.getYear(datetime.now()),
        users: null,
        appIds: null,
    }
};
