import React, {useEffect, useState} from "react";
import {Prompt} from "react-router-dom";
import {Button, CircularProgress, IconButton, Typography} from "@mui/material";
import {NavigateBefore as PrevIcon, NavigateNext as NextIcon} from "@mui/icons-material";
import {VerticalDivider} from "@atttomyx/react-components";
import {AppBrandingForm, AppGeneralFields, AppNotificationForm, AppRedactedForm} from "../forms";
import {internalAppService} from "../../services";
import {arrays, forms, strings} from "@atttomyx/shared-utils";
import {verbiage} from "@atttomyx/shared-constants";
import "./editAppPage.css";

const toData = (app) => {
    return {
        type: app.type,
        welcomeAdmin: app.welcomeAdmin,
        welcomeUser: app.welcomeUser,
        valid: true,
    }
};

const toBranding = (app) => {
    return {
        title: app.title,
        url: app.url,
        logoUrl: app.logoUrl,
        valid: true,
    }
};

const toNotifications = (app) => {
    const notifications = app.notifications || {};

    return {
        defaultDelivery: notifications.defaultDelivery,
        projectId: notifications.projectId,
        authJson: null,
        valid: strings.isNotBlank(notifications.defaultDelivery),
    }
};

const toRedactedFields = (app) => {
    return {
        fields: app.redactedFields || [],
        settings: app.redactedSettings || [],
        valid: true,
    }
};

const EditAppPage = (props) => {
    const {snackbar, match, apps, filters, onSave, onPrev, onNext} = props;
    const [app, setApp] = useState({});
    const [data, setData] = useState(toData({}));
    const [branding, setBranding] = useState(toBranding({}));
    const [notifications, setNotifications] = useState(toNotifications({}));
    const [redactedFields, setRedactedFields] = useState(toRedactedFields({}));
    const [saving, setSaving] = useState(false);
    const [ prevId, setPrevId ] = useState(null);
    const [ nextId, setNextId ] = useState(null);

    const loadApp = (appId) => {
        const previousId = app.id;
        const filtered = filters.filter(apps);
        const found = arrays.findEntity(filtered, appId) || {};
        const ids = arrays.getPrevNextIds(filtered, found);

        setApp(found);
        setPrevId(ids.prevId);
        setNextId(ids.nextId);

        if (previousId && previousId !== appId && found.id === appId) {
            snackbar.setInfo("Loaded " + found.title);
        }
    };

    const redirectToPrev = () => {
        onPrev(prevId);
        loadApp(prevId);
    }

    const redirectToNext = () => {
        onNext(nextId);
        loadApp(nextId);
    }

    useEffect(() => {
        const appId = match.params.id;

        loadApp(appId);
    }, []);

    useEffect(() => {
        setData(toData(app));
        setBranding(toBranding(app));
        setNotifications(toNotifications(app));
        setRedactedFields(toRedactedFields(app));
    }, [app]);

    const resetForm = () => {
        setData(toData(app));
        setBranding(toBranding(app));
        setNotifications(toNotifications(app));
        setRedactedFields(toRedactedFields(app));
    }

    const submitForm = () => {
        setSaving(true);

        const modified = {
            title: branding.title,
            url: branding.url,
            logoUrl: branding.logoUrl,
            type: data.type,
            welcomeAdmin: data.welcomeAdmin,
            welcomeUser: data.welcomeUser,
            defaultDelivery: notifications.defaultDelivery,
            projectId: notifications.projectId,
            authJson: notifications.authJson,
            redactedFields: redactedFields.fields,
            redactedSettings: redactedFields.settings,
        };

        const success = (account) => {
            setApp(account);
            setSaving(false);

            snackbar.setSuccess("App saved");
            onSave(account);
        };

        const failure = (err) => {
            setSaving(false);
            snackbar.setError(err);
        };

        internalAppService.updateApp(app.id, modified, success, failure);
    }

    const valid = data.valid && branding.valid && notifications.valid && redactedFields.valid;
    const modified = forms.differ(toData(app), data)
        || forms.differ(toBranding(app), branding)
        || forms.differ(toNotifications(app), notifications)
        || forms.differ(toRedactedFields(app), redactedFields);

    return saving ?
        <div className="edit-app-page">
            <CircularProgress size="80px"/>
        </div> :
        <div className="edit-app-page">
            <div className="cards">
                <div className="card">
                    <Typography variant="h5" paragraph={true}>
                        Branding
                    </Typography>
                    <AppBrandingForm
                        snackbar={snackbar}
                        appId={app.id}
                        data={branding}
                        onChange={setBranding}
                        showImage={true}
                        imageService={internalAppService}
                    />
                </div>
                <div className="card">
                    <Typography variant="h5" paragraph={true}>
                        General
                    </Typography>
                    <AppGeneralFields
                        data={data}
                        onChange={setData}
                    />
                </div>
                <div className="card">
                    <Typography variant="h5" paragraph={true}>
                        Notifications
                    </Typography>
                    <AppNotificationForm
                        data={notifications}
                        onChange={setNotifications}
                    />
                </div>
                <div className="card">
                    <AppRedactedForm
                        data={redactedFields}
                        onChange={setRedactedFields}
                    />
                </div>
            </div>
            <div className="actions">
                <IconButton color="secondary" title="Previous app"
                            disabled={!prevId}
                            onClick={redirectToPrev}>
                    <PrevIcon/>
                </IconButton>
                <IconButton color="secondary" title="Next app"
                            disabled={!nextId}
                            onClick={redirectToNext}>
                    <NextIcon/>
                </IconButton>
                <VerticalDivider/>
                <Button color="secondary" variant="text"
                        disabled={!modified}
                        onClick={resetForm}>
                    Undo
                </Button>
                <Button color="primary" size="large"
                        disabled={!valid || !modified}
                        onClick={submitForm}>
                    Save
                </Button>
            </div>
            <Prompt when={modified} message={verbiage.UNSAVED_CHANGES}/>
        </div>
}

export default EditAppPage;
