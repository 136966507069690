import React, {useEffect, useState} from "react";
import {CircularProgress, Dialog, DialogContent, Typography} from "@mui/material";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import {internalCodeService} from "../../services";
import {userUtils} from "../../utils";

const CreateCodeDialog = (props) => {
    const {snackbar, accountId, user, onCancel} = props;
    const [code, setCode] = useState(null);
    const [saving, setSaving] = useState(true);

    const createCode = () => {
        const success = (created) => {
            snackbar.setSuccess("Code created");
            setCode(created);
            setSaving(false);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        };

        if (user.alias) {
            internalCodeService.createLinkCode(accountId, user.alias, success, failure);

        } else {
            internalCodeService.createRecoveryCode(user.email, success, failure);
        }
    };

    useEffect(createCode, []);

    return <Dialog
        open={true}
        aria-labelledby="create-code-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            Code for {userUtils.renderUser(user)}
        </ClosableDialogTitle>
        <DialogContent className="create-code-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <Typography>
                    {code}
                </Typography>}
        </DialogContent>
    </Dialog>
}

export default CreateCodeDialog;
