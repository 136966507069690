import React from "react";
import {TextField} from "@mui/material";
import {SelectField} from "@atttomyx/react-components";
import {AppField} from "../fields";
import {forms, objects} from "@atttomyx/shared-utils";
import {USERS_AGGREGATES} from "../../constants";

const AggregateFilters = (props) => {
    const { data, onChange : onSync, formProps } = props;
    const { apps, appRenderer } = formProps;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);
        modified[field] = value;

        onSync(modified);
    }

    return <div className="aggregate-filters">
        <div className="field">
            <TextField
                label="Year"
                type="number"
                value={forms.sanitizeValue(data.year)}
                onChange={(event) => onChange("year", event.target.value)}
                inputProps={{
                    min: 2023,  // this is as far back as the data goes
                }}
            />
        </div>
        <div className="field">
            {/*NOTE: 2025-06 was the first week collecting "returning users"*/}
            <SelectField
                label="Users"
                value={data.users}
                onChange={(value) => onChange("users", value)}
                options={USERS_AGGREGATES}
            />
        </div>
        <div className="field">
            <AppField
                multiple={true}
                apps={apps}
                appRenderer={appRenderer}
                value={data.appIds}
                onChange={(appIds) => onChange("appIds", appIds)}
            />
        </div>
    </div>
}

export default AggregateFilters;
