import React from "react";
import {TextField} from "@mui/material";
import {AppTypeField} from "../fields";
import {forms, objects} from "@atttomyx/shared-utils";

const AppFilters = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);
        modified[field] = value;

        onSync(modified);
    }

    return <div className="app-filters">
        <div className="field">
            <TextField label="Name"
                       type="text"
                       value={forms.sanitizeValue(data.title)}
                       onChange={(event) => onChange("title", event.target.value)}
            />
        </div>
        <div className="field">
            <AppTypeField
                value={data.type}
                onChange={(type) => onChange("type", type)}
            />
        </div>
    </div>
}

export default AppFilters;
