import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {AppBar, BottomNavigation, BottomNavigationAction, Drawer, Stack, Typography} from "@mui/material";
import {styled} from "@mui/styles";
import {
    AccountTree as AccountsIcon,
    Apps as AppsIcon,
    CreditCard as BillingIcon,
    MoreHoriz as MoreIcon,
    People as UsersIcon,
} from "@mui/icons-material";
import MoreMenu from "./moreMenu";
import {arrays} from "@atttomyx/shared-utils";
import {router} from "@atttomyx/react-utils";
import {
    PAGE_ACCOUNT,
    PAGE_ACCOUNTS,
    PAGE_APP,
    PAGE_APPS,
    PAGE_BILLING,
    PAGE_BILLINGS,
    PAGE_USER,
    PAGE_USERS,
    PAGES_MAIN,
    PAGES_MORE
} from "../../constants";
import "./footer.css";

const ActiveAccountsIcon = styled(AccountsIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
}));
const InactiveAccountsIcon = styled(AccountsIcon)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

const ActiveAppsIcon = styled(AppsIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
}));
const InactiveAppsIcon = styled(AppsIcon)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

const ActiveBillingIcon = styled(BillingIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
}));
const InactiveBillingIcon = styled(BillingIcon)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

const ActiveMoreIcon = styled(MoreIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
}));
const InactiveMoreIcon = styled(MoreIcon)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

const ActiveUsersIcon = styled(UsersIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
}));
const InactiveUsersIcon = styled(UsersIcon)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

const PAGE_MORE = "more";
const PAGE_OTHER = "other";

const NavButton = (props) => {
    const { label, active, icon, onClick } = props;

    return (
        <Stack direction="column" onClick={onClick} className="nav-button">
            {icon}
            <Typography variant="caption" color={active ? "primary" : "text.secondary"}>
                {label}
            </Typography>
        </Stack>
    )
};

const Footer = (props) => {
    const {dimensions, history, user, onLogout} = props;
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        dimensions.onResize();
    }, []);

    const path = router.getCurrentPage(history);
    let page = PAGE_OTHER;

    if (arrays.contains(PAGES_MAIN, path)) {
        page = path;

    } else if (arrays.contains(PAGES_MORE, path)) {
        page = PAGE_MORE;

    } else if (path && path.startsWith(PAGE_APP)) {
        page = PAGE_APPS;

    } else if (path && path.startsWith(PAGE_ACCOUNT)) {
        page = PAGE_ACCOUNTS;

    } else if (path && path.startsWith(PAGE_USER)) {
        page = PAGE_USERS;

    } else if (path && path.startsWith(PAGE_BILLING)) {
        page = PAGE_BILLINGS;
    }

    const renderBottom = () => {
        return (
            <AppBar className="bottom-nav" position="fixed" color="default">
                <BottomNavigation
                    value={page}
                    showLabels={true}
                >
                    {user.roles.apps ?
                        <BottomNavigationAction
                            value={PAGE_APPS}
                            label="Apps"
                            icon={<AppsIcon/>}
                            onClick={() => router.redirectTo(history, PAGE_APPS)}
                        /> : null}
                    {user.roles.billing ?
                        <BottomNavigationAction
                            value={PAGE_BILLINGS}
                            label="Billings"
                            icon={<BillingIcon/>}
                            onClick={() => router.redirectTo(history, PAGE_BILLINGS)}
                        /> : null}
                    {user.roles.accounts ?
                        <BottomNavigationAction
                            value={PAGE_ACCOUNTS}
                            label="Accounts"
                            icon={<AccountsIcon/>}
                            onClick={() => router.redirectTo(history, PAGE_ACCOUNTS)}
                        /> : null}
                    {user.roles.users ?
                        <BottomNavigationAction
                            value={PAGE_USERS}
                            label="Users"
                            icon={<UsersIcon/>}
                            onClick={() => router.redirectTo(history, PAGE_USERS)}
                        /> : null}
                    <BottomNavigationAction
                        value={PAGE_MORE}
                        label="More"
                        icon={<MoreIcon/>}
                        component="div"
                        onClick={() => setShowMore(true)}
                    />
                </BottomNavigation>
            </AppBar>
        )
    };

    const renderLeft = () => {
        return (
            <Drawer
                variant="permanent"
                anchor="left"
                open={false}
                className="left-nav"
            >
                <div className="nav-buttons">
                    {user.roles.apps ?
                        <NavButton
                            label="Apps"
                            active={page === PAGE_APPS}
                            icon={page === PAGE_APPS ? <ActiveAppsIcon/> : <InactiveAppsIcon/>}
                            onClick={() => router.redirectTo(history, PAGE_APPS)}
                        /> : null}
                    {user.roles.billing ?
                        <NavButton
                            label="Billing"
                            active={page === PAGE_BILLINGS}
                            icon={page === PAGE_BILLINGS ? <ActiveBillingIcon/> : <InactiveBillingIcon/>}
                            onClick={() => router.redirectTo(history, PAGE_BILLINGS)}
                        /> : null}
                    {user.roles.accounts ?
                        <NavButton
                            label="Accounts"
                            active={page === PAGE_ACCOUNTS}
                            icon={page === PAGE_ACCOUNTS ? <ActiveAccountsIcon/> : <InactiveAccountsIcon/>}
                            onClick={() => router.redirectTo(history, PAGE_ACCOUNTS)}
                        /> : null}
                    {user.roles.users ?
                        <NavButton
                            label="Users"
                            active={page === PAGE_USERS}
                            icon={page === PAGE_USERS ? <ActiveUsersIcon/> : <InactiveUsersIcon/>}
                            onClick={() => router.redirectTo(history, PAGE_USERS)}
                        /> : null}
                    <NavButton
                        label="More"
                        active={page === PAGE_MORE}
                        icon={page === PAGE_MORE ? <ActiveMoreIcon/> : <InactiveMoreIcon/>}
                        onClick={() => setShowMore(true)}
                    />
                </div>
            </Drawer>
        )
    };

    return <div className="footer">
        {dimensions.landscape && dimensions.short ? renderLeft() : renderBottom()}
        {showMore ?
            <MoreMenu
                user={user}
                onCancel={() => setShowMore(false)}
                onLogout={onLogout}
            /> : null}
    </div>
}

export default withRouter(Footer);
