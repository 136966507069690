import React, {useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography} from "@mui/material";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import {ReleaseForm} from "../forms";
import {internalAppService} from "../../services";
import {forms} from "@atttomyx/shared-utils";

const freshData = () => {
    return {
        releaseType: null,
        valid: false,
    }
};

const ReleaseDialog = (props) => {
    const { snackbar, app, onCancel, onSave } = props;
    const [ data, setData ] = useState(freshData());
    const [ saving, setSaving ] = useState(false);

    const submitForm = () => {
        setSaving(true);

        const success = (saved) => {
            snackbar.setSuccess(app.title + " released");
            setSaving(false);
            onSave(saved);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        };

        internalAppService.releaseApp(app.id, data.releaseType, success, failure);
    }

    return <Dialog
        open={true}
        aria-labelledby="release-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            Release
        </ClosableDialogTitle>
        <DialogContent className="release-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <>
                    <Typography>
                        This will increment the version for {app.title}
                    </Typography>
                    <ReleaseForm
                        data={data}
                        onChange={setData}
                    />
                </>}
        </DialogContent>
        {!saving ? <DialogActions>
            <Button color="primary"
                    disabled={!data.valid || !forms.differ(freshData(), data)}
                    onClick={submitForm}>
                Save
            </Button>
        </DialogActions> : null}
    </Dialog>
}

export default ReleaseDialog;
