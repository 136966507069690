import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {useFiltered} from "@atttomyx/shared-hooks";
import {IconButton, Typography} from "@mui/material";
import {Delete as DeleteIcon, Edit as EditIcon,} from "@mui/icons-material";
import {Cards, ConfirmDeleteDialog, FiltersAccordion, FloatingAddButton} from "@atttomyx/react-components";
import {CategoryFilters} from "../filters";
import {CategoryCard} from "../cards";
import {AddCategoryDialog} from "../dialogs";
import {internalCategoryService} from "../../services";
import {router} from "@atttomyx/react-utils";
import {PAGE_CATEGORY} from "../../constants";

const CategoriesPage = (props) => {
    const { history, snackbar, dimensions, categories, filters, onSave, onDelete } = props;
    const [ category, setCategory ] = useState(null);
    const [ showAdd, setShowAdd ] = useState(false);
    const [ showDelete, setShowDelete ] = useState(false);
    const [ showFilters, setShowFilters ] = useState(false);
    const filtered = useFiltered(categories, filters);

    useEffect(() => {
        if (!category) {
            setShowAdd(false);
            setShowDelete(false);
        }
    }, [category]);

    return <div className="categories-page">
        <Typography variant="h5" paragraph={true}>
            Categories
        </Typography>
        <div className="field">
            <FiltersAccordion
                filters={filters}
                form={CategoryFilters}
                open={showFilters}
                onOpen={() => setShowFilters(true)}
                onClose={() => setShowFilters(false)}
            />
        </div>
        <Cards
            items={filtered}
            paging={filters.paging}
            dimensions={dimensions}
            renderer={(category) =>
                <CategoryCard key={category.id} category={category} filters={filters}>
                    <IconButton color="secondary" title="Delete" className="delete"
                                onClick={() => {
                                    setCategory(category);
                                    setShowDelete(true);
                                }}>
                        <DeleteIcon/>
                    </IconButton>
                    <IconButton color="primary" title="Edit"
                                onClick={() => router.redirectTo(history, PAGE_CATEGORY + "/" + category.id)}>
                        <EditIcon/>
                    </IconButton>
                </CategoryCard>}
        />
        <FloatingAddButton
            title="Add category"
            position={!dimensions.landscape || !dimensions.short ? "higher" : undefined}
            center={dimensions.landscape && !dimensions.short}
            onClick={() => {
                setShowAdd(true);
            }}
        />
        {showAdd ? <AddCategoryDialog
            snackbar={snackbar}
            onCancel={() => {
                setShowAdd(false);
            }}
            onSave={(saved) => {
                setShowAdd(false);
                onSave(saved);
            }}
        /> : null}
        {showDelete && category ? <ConfirmDeleteDialog
            snackbar={snackbar}
            title={category.title}
            onCancel={() => {
                setShowDelete(false);
                setCategory(null);
            }}
            onDelete={(adId) => {
                setShowDelete(false);
                setCategory(null);
                onDelete(adId);
            }}
            deleter={(success, failure) => internalCategoryService.deleteCategory(category.id, success, failure)}
        /> : null}
    </div>
}

export default withRouter(CategoriesPage);
