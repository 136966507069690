import axios from "axios";

export const listLogins = (cursor, limit, success, failure) => {
    let url = "/api/v1/internal/login/list?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const listAggregates = (cursor, limit, success, failure) => {
    let url = "/api/v1/internal/login/aggregates?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};
