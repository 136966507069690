import React from "react";
import {Card, CardActions, CardContent} from "@mui/material";
import {CardData, CardTitle, UserAvatar} from "@atttomyx/react-components";
import {datetime, formatters, objects, strings} from "@atttomyx/shared-utils";
import {userUtils} from "../../utils";
import "./userCard.css";

const UserCard = (props) => {
    const { user, filters, userIdToLogin, children } = props;

    return <Card className="user-card" raised={true}>
        <CardContent>
            <CardTitle>
                <UserAvatar user={user} mode="no-title" clickable={true}/>
                {userUtils.renderUser(user)}
            </CardTitle>
            <CardData
                filters={filters}
                data={user}
                keyToMetadata={{
                    installationIds: {
                        title: "Installations",
                        formatter: formatters.arrayLength,
                        hidden: strings.isBlank(user.alias),
                    },
                    roles: {
                        title: "Roles",
                        hidden: user.roles.length === 0,
                    },
                    email: {
                        title: "Email",
                        hidden: strings.isNotBlank(user.alias),
                        showFiltered: true,
                    },
                    phone: {
                        title: "Phone",
                        formatter: formatters.phoneNumber,
                        showFiltered: true,
                    },
                    birthday: {
                        title: "Birthday",
                        formatter: (value) => value ? datetime.getFullDate(value) : null,
                    },
                    created: {
                        title: "Created",
                        formatter: datetime.getFullDateAndTime,
                    },
                    updated: {
                        title: "Updated",
                        formatter: datetime.getFullDateAndTime,
                    },
                    login: {
                        title: "Login",
                        formatter: datetime.getFullDateAndTime,
                        getter: (item) => {
                            const login = userIdToLogin[item.id] || {};

                            return login.created;
                        },
                        hidden: objects.nullOrUndefined(userIdToLogin[user.id]),
                    },
                }}
            />
        </CardContent>
        <CardActions disableSpacing={true}>
            {children}
        </CardActions>
    </Card>
}

export default UserCard;
