import React, {useEffect, useMemo, useState} from "react";
import {withRouter} from "react-router-dom";
import {isMobile, isMobileOnly} from "react-device-detect";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import {CircularProgress, IconButton, Menu, MenuItem} from "@mui/material";
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    Email as EmailIcon,
    Fingerprint as PasswordIcon,
    Login as StealthIcon,
    Phone as CallIcon,
    Sms as SmsIcon
} from "@mui/icons-material";
import {useSanitizedUsers} from "../../hooks";
import {useFiltered} from "@atttomyx/shared-hooks";
import {useQuery} from "@atttomyx/react-hooks";
import {Cards, ConfirmDeleteDialog, FiltersAccordion, FloatingAddButton} from "@atttomyx/react-components";
import {AccountField} from "../fields";
import {UserFilters} from "../filters";
import {UserCard} from "../cards";
import {AddToAccountDialog, CreateCodeDialog} from "../dialogs";
import {authService, internalUserService} from "../../services";
import {strings} from "@atttomyx/shared-utils";
import {communication, confirm, router} from "@atttomyx/react-utils";
import {userUtils} from "../../utils";
import {misc} from "@atttomyx/shared-constants";
import {
    ACCOUNT_ID_INTERNAL,
    APP_TYPE_MULTI_TENANT,
    APP_TYPE_SINGLE_TENANT,
    PAGE_PROFILE,
    PAGE_USER
} from "../../constants";

const UsersPage = (props) => {
    const {history, snackbar, dimensions, user: me, apps, accounts, accountRenderer, users: allUsers, logins, filters,
        onSave, onDelete} = props;

    const [user, setUser] = useState(null);
    const [userIdToLogin, setUserIdToLogin] = useState({});
    const [showFilters, setShowFilters] = useState(false);
    const [showAddToAccount, setShowAddToAccount] = useState(false);
    const [showCreateCode, setShowCreateCode] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [deleting, setDeleting] = useState(null);

    const users = useSanitizedUsers(allUsers, filters);
    const filtered = useFiltered(users, filters, userUtils.sortByNameAndAlias);
    const query = useQuery();

    const accountId = filters.options.accountId;
    const restrictedAccount = accountId === ACCOUNT_ID_INTERNAL;
    const accountName = accountRenderer.render(accountId);

    const account = useMemo(() => {
        return accounts.find(candidate => candidate.id === accountId);
    }, [accounts, accountId]);

    const app = useMemo(() => {
        return account ? apps.find(candidate => candidate.id === account.appId) : null;
    }, [apps, account]);

    const removeFromAccount = (user) => {
        const success = (saved) => {
            snackbar.setSuccess(userUtils.renderUser(saved) + " removed from " + accountName);
            setDeleting(null);
            onSave(saved);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setDeleting(null);
        };

        internalUserService.removeFromAccount(user.id, accountId, success, failure);
    };

    const stealthLogin = (user) => {
        const success = (jwt) => {
            window.open(`${app.url}/deep-link/stealth?token=${jwt}`, "stealth");
        };

        authService.stealthLogin(account.appId, accountId, user.id, success, snackbar.setError);
    };

    useEffect(() => {
        const accountId = filters.options.accountId;

        if (accountId) {
            const accountLogins = {};

            logins
                .filter(login => login.accountId === accountId)
                .forEach(login => {
                    accountLogins[login.userId] = login;
                });

            setUserIdToLogin(accountLogins);

        } else {
            setUserIdToLogin({});
        }
    }, [logins, filters.options]);

    useEffect(() => {
        const accountId = query.get("accountId");

        if (accountId) {
            filters.setOptions({
                accountId: accountId,
            });
        }
    }, []);

    return <div className="users-page">
        <div className="field">
            <AccountField
                label="Which users?"
                required={true}
                accounts={accounts}
                accountRenderer={accountRenderer}
                value={accountId}
                onChange={(accountId) => filters.setOptions({
                    accountId: accountId,
                })}
            />
        </div>
        <div className="field">
            <FiltersAccordion
                filters={filters}
                form={UserFilters}
                open={showFilters}
                onOpen={() => setShowFilters(true)}
                onClose={() => setShowFilters(false)}
            />
        </div>
        <Cards
            items={filtered}
            paging={filters.paging}
            dimensions={dimensions}
            renderer={(user) => user.id === deleting ?
                <CircularProgress color="secondary" size="40px"/> :
                <UserCard key={user.id} user={user} filters={filters} userIdToLogin={userIdToLogin}>
                    <IconButton color="secondary" title="Delete" className="delete"
                                disabled={(me.id === user.id && restrictedAccount) || (restrictedAccount && !me.roles.admin)}
                                onClick={() => {
                                    if (misc.NONE === accountId) {
                                        setUser(user);
                                        setShowDelete(true);

                                    } else {
                                        confirm.confirm(
                                            `Are you sure you want to remove ${userUtils.renderUser(user)} from ${accountName}?`,
                                            () => removeFromAccount(user))
                                    }
                                }}>
                        <DeleteIcon/>
                    </IconButton>
                    <IconButton color="primary" title="Stealth login"
                                disabled={me.id === user.id || restrictedAccount || misc.NONE === accountId
                                    || (app.type !== APP_TYPE_MULTI_TENANT && app.type !== APP_TYPE_SINGLE_TENANT)}
                                onClick={() => confirm.confirm(
                                    `Are you sure you want to stealth in to ${accountName} as ${userUtils.renderUser(user)}?`,
                                    () => stealthLogin(user))}>
                        <StealthIcon/>
                    </IconButton>
                    <IconButton color="primary" title="Create code"
                                disabled={me.id === user.id}
                                onClick={() => confirm.confirm(
                                    `Are you sure you want to create a code for ${userUtils.renderUser(user)}?`,
                                    () => {
                                        setUser(user);
                                        setShowCreateCode(true);
                                    })}>
                        <PasswordIcon/>
                    </IconButton>
                    {isMobileOnly ?
                        <IconButton color="primary" title="Call"
                                    disabled={me.id === user.id || strings.isBlank(user.phone)}
                                    onClick={() => communication.popCall(user.phone)}>
                            <CallIcon/>
                        </IconButton> : null}
                    {isMobile ?
                        <IconButton color="primary" title="Text"
                                    disabled={me.id === user.id || strings.isBlank(user.phone)}
                                    onClick={() => communication.popSms(user.phone)}>
                            <SmsIcon/>
                        </IconButton> : null}
                    <IconButton color="primary" title="Email"
                                disabled={me.id === user.id || strings.isNotBlank(user.alias)}
                                onClick={() => communication.popEmail(user.email)}>
                        <EmailIcon/>
                    </IconButton>
                    <IconButton color="primary" title="Edit"
                                disabled={restrictedAccount && !me.roles.admin}
                                onClick={() => me.id === user.id && restrictedAccount
                                    ? router.redirectTo(history, PAGE_PROFILE)
                                    : router.redirectTo(history, `${PAGE_USER}/${accountId}/${user.id}`)}>
                        <EditIcon/>
                    </IconButton>
                </UserCard>}
        />
        <PopupState variant="popover" popupId="add">
            {(popupState) => (
                <>
                    <FloatingAddButton
                        title="Add user"
                        position={!dimensions.landscape || !dimensions.short ? "higher" : undefined}
                        center={dimensions.landscape && !dimensions.short}
                        disabled={!accountId || (restrictedAccount && !me.roles.admin)}
                        {...bindTrigger(popupState)}
                    />
                    <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={() => {
                            popupState.close();
                            setShowAddToAccount(true);
                        }}>Select existing user</MenuItem>
                        <MenuItem onClick={() => router.redirectTo(history, `${PAGE_USER}/${accountId}`)}>Create new user</MenuItem>
                    </Menu>
                </>
            )}
        </PopupState>
        {showAddToAccount ?
            <AddToAccountDialog
                snackbar={snackbar}
                accountId={accountId}
                accounts={accounts}
                accountRenderer={accountRenderer}
                users={allUsers}
                onCancel={() => setShowAddToAccount(false)}
                onSave={(user) => {
                    setShowAddToAccount(false);
                    onSave(user);
                }}
            /> : null}
        {showCreateCode && user ?
            <CreateCodeDialog
                snackbar={snackbar}
                accountId={accountId}
                user={user}
                onCancel={() => {
                    setUser(null);
                    setShowCreateCode(false);
                }}
            /> : null}
        {showDelete && user ?
            <ConfirmDeleteDialog
                snackbar={snackbar}
                title={userUtils.renderUser(user)}
                onCancel={() => {
                    setShowDelete(false);
                    setUser(null);
                }}
                onDelete={(userId) => {
                    setShowDelete(false);
                    setUser(null);
                    onDelete(userId);
                }}
                deleter={(success, failure) => internalUserService.deleteUser(user.id, success, failure)}
            /> : null}
    </div>
}

export default withRouter(UsersPage);
