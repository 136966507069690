import {useEffect, useState} from "react";
import {arrays} from "@atttomyx/shared-utils";
import {userUtils} from "../utils";

const useSanitizedUsers = (allUsers, filters) => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const accountId = filters.options.accountId;

        if (accountId) {
            const accountUsers = [];

            allUsers
            .filter(user => userUtils.isInAccount(user, accountId))
            .forEach(user => {
                const sanitized = userUtils.sanitizeUser(user, accountId);

                arrays.addTo(accountUsers, sanitized);
            });

            setUsers(accountUsers);

        } else {
            setUsers([]);
        }
    }, [allUsers, filters.options]);

    return users;
};

export default useSanitizedUsers;
