import React from "react";
import {FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import {objects, strings} from "@atttomyx/shared-utils";
import {themes} from "@atttomyx/shared-constants";

const UserSettingsForm = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = strings.isNotBlank(modified.theme);

        onSync(modified);
    }

    return <div className="user-settings-form">
        <div className="option">
            <FormLabel id="theme">Theme</FormLabel>
            <RadioGroup row={true} aria-labelledby="theme"
                        onChange={(event) => onChange("theme", event.target.value)}>
                <FormControlLabel
                    value={themes.LIGHT}
                    label="Light"
                    control={<Radio checked={data.theme === themes.LIGHT} color="primary"/>}
                />
                <FormControlLabel
                    value={themes.DARK}
                    label="Dark"
                    control={<Radio checked={data.theme === themes.DARK} color="primary"/>}
                />
            </RadioGroup>
        </div>
    </div>
}

export default UserSettingsForm;
