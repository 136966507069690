import React, {useState} from "react";
import {makeStyles} from "@mui/styles";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent} from "@mui/material";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import {AdForm} from "../forms";
import {internalAdService} from "../../services";
import {forms} from "@atttomyx/shared-utils";

const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: "400px",
    },
}));

const toData = (ad) => {
    return {
        appId: ad.appId,
        imageUrl: ad.imageUrl,
        clickUrl: ad.clickUrl,
        valid: ad.appId && ad.imageUrl && ad.clickUrl,
    }
};

const AdDialog = (props) => {
    const { snackbar, apps, appRenderer, ad, onCancel, onSave } = props;
    const [ data, setData ] = useState(toData(ad));
    const [ saving, setSaving ] = useState(false);

    const submitForm = () => {
        setSaving(true);

        const success = (saved) => {
            snackbar.setSuccess("Ad saved");
            setSaving(false);
            onSave(saved);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        };

        const modified = {
            appId: data.appId,
            imageUrl: data.imageUrl,
            clickUrl: data.clickUrl,
        };

        if (ad.id) {
            internalAdService.updateAd(ad.id, modified, success, failure);

        } else {
            internalAdService.addAd(modified, success, failure);
        }
    }

    return <Dialog
        open={true}
        aria-labelledby="ad-dialog"
        classes={useStyles()}
    >
        <ClosableDialogTitle onClose={onCancel}>
            Ad
        </ClosableDialogTitle>
        <DialogContent className="ad-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <AdForm
                    apps={apps}
                    appRenderer={appRenderer}
                    data={data}
                    onChange={setData}
                    imageService={adService}
                />}
        </DialogContent>
        {!saving ? <DialogActions>
            <Button color="primary"
                    disabled={!data.valid || !forms.differ(toData(ad), data)}
                    onClick={submitForm}>
                Save
            </Button>
        </DialogActions> : null}
    </Dialog>
}

export default AdDialog;
