import React, {useState} from "react";
import {Checkbox, FormControlLabel} from "@mui/material";
import {AppField, BillingField} from "../fields";
import {AddBillingDialog} from "../dialogs";
import {forms, objects} from "@atttomyx/shared-utils";

const AccountForm = (props) => {
    const { snackbar, apps, appRenderer, billingRenderer, data, onChange : onSync, onSaveBilling } = props;
    const [ showAddBilling, setShowAddBilling ] = useState(false);

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;

        modified.valid = modified.appId && modified.billingId;

        onSync(modified);
    }

    return <div className="account-form">
        <div className="field">
            <AppField
                apps={apps}
                appRenderer={appRenderer}
                required={true}
                value={data.appId}
                onChange={(appId) => onChange("appId", appId)}
            />
        </div>
        <div className="field">
            <BillingField
                billingRenderer={billingRenderer}
                required={true}
                value={data.billingId}
                onChange={(billingId) => onChange("billingId", billingId)}
                onAddNew={() => setShowAddBilling(true)}
            />
        </div>
        <div className="option">
            <FormControlLabel labelPlacement="end"
                              label="Disabled"
                              control={<Checkbox color="primary"
                                                 checked={forms.sanitizeOption(data.disabled)}
                                                 onChange={(event) => onChange("disabled", event.target.checked)}
                              />}
            />
        </div>
        <div className="option">
            <FormControlLabel labelPlacement="end"
                              label="Allow new users to join"
                              control={<Checkbox color="primary"
                                                 checked={forms.sanitizeOption(data.allowJoin)}
                                                 onChange={(event) => onChange("allowJoin", event.target.checked)}
                              />}
            />
        </div>
        {showAddBilling ? <AddBillingDialog
            snackbar={snackbar}
            onCancel={() => setShowAddBilling(false)}
            onSave={(billing) => {
                setShowAddBilling(false);
                onChange("billingId", billing.id);
                onSaveBilling(billing);
            }}
        /> : null}
    </div>
}

export default AccountForm;
