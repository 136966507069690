import React, {useState} from "react";
import {Button, IconButton, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {AddCircle as AddIcon, Delete as RemoveIcon} from "@mui/icons-material";
import {AddRoleDialog} from "../dialogs";
import {arrays} from "@atttomyx/shared-utils";
import "./userRoleForm.css";

const UserRoleForm = (props) => {
    const { roles, onChange } = props;
    const [ showAddRole, setShowAddRole ] = useState(false);

    const removeRole = (role) => {
        if (arrays.contains(roles, role)) {
            const modified = [].concat(roles);

            arrays.removeFrom(modified, role);

            onChange(modified);
        }
    };

    const addRole = (role) => {
        if (!arrays.contains(roles, role)) {
            const modified = [].concat(roles);

            arrays.addTo(modified, role);

            onChange(modified);
        }
    };

    return <div className="user-role-form">
        <Typography variant="h5">
            Roles
        </Typography>
        <Table size="small" cellPadding={0} cellSpacing={0} className="striped roles">
            <TableBody>
                {roles.map(role =>
                    <TableRow key={role}>
                        <TableCell width="90%">
                            {role}
                        </TableCell>
                        <TableCell width="10%">
                            <IconButton color="secondary" title={"Remove " + role}
                                        onClick={() => removeRole(role)}>
                                <RemoveIcon/>
                            </IconButton>
                        </TableCell>
                    </TableRow>)}
            </TableBody>
        </Table>
        <Button color="primary" variant="text" className="add-role"
                onClick={() => setShowAddRole(true)}>
            <AddIcon/> Add Role
        </Button>
        {showAddRole ?
            <AddRoleDialog
                onCancel={() => setShowAddRole(false)}
                onAdd={(role) => {
                    addRole(role);
                    setShowAddRole(false);
                }}
            /> : null}
    </div>
};

export default UserRoleForm;
