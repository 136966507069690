import React, {useState} from "react";
import {Prompt} from "react-router-dom";
import {Button, CircularProgress, Typography} from "@mui/material";
import {AppBrandingForm, AppGeneralFields, AppNotificationForm, AppRedactedForm} from "../forms";
import {internalAppService} from "../../services";
import {forms} from "@atttomyx/shared-utils";
import {verbiage} from "@atttomyx/shared-constants";
import {APP_LOGO_DEFAULT} from "../../constants";
import "./addAppPage.css";

const freshData = () => {
    return {
        type: null,
        welcomeAdmin: null,
        welcomeUser: null,
        valid: true,
    }
};

const freshBranding = () => {
    return {
        title: null,
        url: null,
        logoUrl: APP_LOGO_DEFAULT,
        valid: false,
    }
};

const freshNotifications = () => {
    return {
        defaultDelivery: null,
        projectId: null,
        authJson: null,
        valid: false,
    }
};

const freshRedactedFields = () => {
    return {
        fields: [],
        settings: [],
        valid: true,
    }
};

const AddAppPage = (props) => {
    const {snackbar, onSave} = props;
    const [data, setData] = useState(freshData());
    const [branding, setBranding] = useState(freshBranding());
    const [notifications, setNotifications] = useState(freshNotifications());
    const [redactedFields, setRedactedFields] = useState(freshRedactedFields({}));
    const [saving, setSaving] = useState(false);

    const resetForm = () => {
        setData(freshData());
        setBranding(freshBranding());
        setNotifications(freshNotifications());
        setRedactedFields(freshRedactedFields());
    }

    const submitForm = () => {
        setSaving(true);

        const modified = {
            title: branding.title,
            url: branding.url,
            logoUrl: branding.logoUrl,
            type: data.type,
            welcomeAdmin: data.welcomeAdmin,
            welcomeUser: data.welcomeUser,
            defaultDelivery: notifications.defaultDelivery,
            projectId: notifications.projectId,
            authJson: notifications.authJson,
            redactedFields: redactedFields.fields,
            redactedSettings: redactedFields.settings,
        };

        const success = (account) => {
            resetForm();
            setSaving(false);

            snackbar.setSuccess("App saved");
            onSave(account);
        };

        const failure = (err) => {
            setSaving(false);
            snackbar.setError(err);
        };

        internalAppService.addApp(modified, success, failure);
    }

    const valid = data.valid && branding.valid && notifications.valid && redactedFields.valid;
    const modified = forms.differ(freshData(), data)
        || forms.differ(freshBranding(), branding)
        || forms.differ(freshNotifications(), notifications)
        || forms.differ(freshRedactedFields(), redactedFields);

    return saving ?
        <div className="add-app-page">
            <CircularProgress size="80px"/>
        </div> :
        <div className="add-app-page">
            <div className="cards">
                <div className="card">
                    <Typography variant="h5" paragraph={true}>
                        Branding
                    </Typography>
                    <AppBrandingForm
                        snackbar={snackbar}
                        data={branding}
                        onChange={setBranding}
                        showImage={false}
                    />
                </div>
                <div className="card">
                    <Typography variant="h5" paragraph={true}>
                        General
                    </Typography>
                    <AppGeneralFields
                        data={data}
                        onChange={setData}
                    />
                </div>
                <div className="card">
                    <Typography variant="h5" paragraph={true}>
                        Notifications
                    </Typography>
                    <AppNotificationForm
                        data={notifications}
                        onChange={setNotifications}
                    />
                </div>
                <div className="card">
                    <AppRedactedForm
                        data={redactedFields}
                        onChange={setRedactedFields}
                    />
                </div>
            </div>
            <div className="actions">
                <Button color="secondary" variant="text"
                        disabled={!modified}
                        onClick={resetForm}>
                    Undo
                </Button>
                <Button color="primary" size="large"
                        disabled={!valid || !modified}
                        onClick={submitForm}>
                    Save
                </Button>
            </div>
            <Prompt when={modified} message={verbiage.UNSAVED_CHANGES}/>
        </div>
}

export default AddAppPage;
