import React, {useEffect, useMemo, useState} from "react";
import {Prompt} from "react-router-dom";
import {Button, CircularProgress, Divider, IconButton} from "@mui/material";
import {
    Delete as DeleteIcon,
    NavigateBefore as PrevIcon,
    NavigateNext as NextIcon,
    Photo as PrimaryIcon,
    PhotoOutlined as SecondaryIcon
} from "@mui/icons-material";
import {usePaging} from "@atttomyx/shared-hooks";
import {Cards, VerticalDivider} from "@atttomyx/react-components";
import {CategoryForm} from "../forms";
import {AddImageCard, ImageCard} from "../cards";
import {internalCategoryService} from "../../services";
import {arrays, forms, objects, strings} from "@atttomyx/shared-utils";
import {confirm} from "@atttomyx/react-utils";
import {verbiage} from "@atttomyx/shared-constants";
import "./editCategoryPage.css";

const DROPZONE = "dropzone";

const toData = (category) => {
    return {
        status: category.status,
        title: category.title,
        imageUrl: category.imageUrl,
        uuidToImageUrl: category.uuidToImageUrl || {},
        valid: strings.isNotBlank(category.title),
    }
};

const EditCategoryPage = (props) => {
    const {snackbar, dimensions, match, categories, filters, onSave, onPrev, onNext} = props;
    const [category, setCategory] = useState({});
    const [data, setData] = useState(toData({}));
    const [saving, setSaving] = useState(false);
    const [ prevId, setPrevId ] = useState(null);
    const [ nextId, setNextId ] = useState(null);
    const paging = usePaging();

    useEffect(() => {
        paging.setPerPage(100);
    }, []);

    const images = useMemo(() => {
        const list = [];

        if (data.uuidToImageUrl) {
            Object.entries(data.uuidToImageUrl).forEach(([key, value]) => {
                arrays.addTo(list, {
                    uuid: key,
                    imageUrl: value,
                });
            });
        }

        arrays.addTo(list, {
            uuid: DROPZONE,
        });

        return list;
    }, [data.uuidToImageUrl]);

    const loadCategory = (categoryId) => {
        const previousId = category.id;
        const filtered = filters.filter(categories);
        const found = arrays.findEntity(filtered, categoryId) || {};
        const ids = arrays.getPrevNextIds(filtered, found);

        setCategory(found);
        setPrevId(ids.prevId);
        setNextId(ids.nextId);

        if (previousId && previousId !== categoryId && found.id === categoryId) {
            snackbar.setInfo("Loaded " + found.title);
        }
    };

    const redirectToPrev = () => {
        onPrev(prevId);
        loadCategory(prevId);
    }

    const redirectToNext = () => {
        onNext(nextId);
        loadCategory(nextId);
    }

    useEffect(() => {
        const categoryId = match.params.id;

        loadCategory(categoryId);
    }, []);

    useEffect(() => {
        setData(toData(category));
    }, [category]);

    const resetForm = () => {
        setData(toData(category));
    };

    const submitForm = () => {
        setSaving(true);

        const modified = {
            status: data.status,
            title: data.title,
            imageUrl: data.imageUrl,
            uuidToImageUrl: data.uuidToImageUrl,
        };

        const success = (category) => {
            setCategory(category);
            setSaving(false);

            snackbar.setSuccess("Category saved");
            onSave(category);
        };

        const failure = (err) => {
            setSaving(false);
            snackbar.setError(err);
        };

        internalCategoryService.updateCategory(category.id, modified, success, failure);
    };

    const addImage = (image) => {
        const modified = objects.deepCopy(data);

        modified.uuidToImageUrl[image.uuid] = image.imageUrl;

        setData(modified);
    };

    const deleteImage = (uuid) => {
        const modified = objects.deepCopy(data);

        delete modified.uuidToImageUrl[uuid];

        setData(modified);
    };

    const setPrimary = (imageUrl) => {
        const modified = objects.deepCopy(data);

        modified.imageUrl = imageUrl;

        setData(modified);
    };

    const valid = data.valid;
    const modified = forms.differ(toData(category), data);

    return saving ?
        <div className="edit-category-page">
            <CircularProgress size="80px"/>
        </div> :
        <div className="edit-category-page">
            <CategoryForm
                data={data}
                onChange={setData}
            />
            <div className="actions">
                <IconButton color="secondary" title="Previous category"
                            disabled={!prevId}
                            onClick={redirectToPrev}>
                    <PrevIcon/>
                </IconButton>
                <IconButton color="secondary" title="Next category"
                            disabled={!nextId}
                            onClick={redirectToNext}>
                    <NextIcon/>
                </IconButton>
                <VerticalDivider/>
                <Button color="secondary" variant="text"
                        disabled={!modified}
                        onClick={resetForm}>
                    Undo
                </Button>
                <Button color="primary" size="large"
                        disabled={!valid || !modified}
                        onClick={submitForm}>
                    Save
                </Button>
            </div>
            <Divider/>
            <Cards
                label="image"
                items={images}
                paging={paging}
                dimensions={dimensions}
                renderer={(image) =>
                    image.uuid === DROPZONE ?
                        <AddImageCard
                            snackbar={snackbar}
                            categoryId={category.id}
                            onSave={addImage}
                        /> :
                        <ImageCard key={image.uuid} image={image}>
                            <IconButton color="secondary" title="Delete" className="delete"
                                        onClick={() => confirm.confirmDelete(image.uuid, () => deleteImage(image.uuid))}>
                                <DeleteIcon/>
                            </IconButton>
                            <IconButton color="primary" title="Primary"
                                        disabled={data.imageUrl === image.imageUrl}
                                        onClick={() => setPrimary(image.imageUrl)}>
                                {data.imageUrl === image.imageUrl ? <PrimaryIcon/> : <SecondaryIcon/>}
                            </IconButton>
                        </ImageCard>}
            />
            <Prompt when={modified} message={verbiage.UNSAVED_CHANGES}/>
        </div>
}

export default EditCategoryPage;
