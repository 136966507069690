export const APP_ID = "qvbINvDPXcT0Hybb00QI";
export const APP_NAME = "Accounts";
export const APP_TAG_LINE = "Manage apps and accounts";

export const ACCOUNT_ID_INTERNAL = "DgXQxwsOTOK7Zr1wKzG0";
export const ACCOUNT_ID_PLATFORM = "platform";

export const APP_BASE_URL = "https://accounts-app-sxn7bz2dna-uc.a.run.app";
export const WEB_BASE_URL = "https://accounts.milesoft.app";
export const LOCAL_BASE_URL = "http://localhost:3000";

export const NAMESPACE = "accounts";
export const KEY_VERSION = "version";
export const KEY_AUTH_TOKEN = "auth_token";
export const KEY_TEMPORARY_PASSWORD = "temp_code";

export const APP_TYPE_MULTI_TENANT = "MultiTenant";
export const APP_TYPE_SINGLE_TENANT = "SingleTenant";
export const APP_TYPE_MOBILE_ONLY = "MobileOnly";
export const APP_TYPE_INTERNAL = "Internal";
export const APP_TYPE_PLATFORM = "Platform";

export const RELEASE_TYPE_MAJOR = "Major";
export const RELEASE_TYPE_MINOR = "Minor";
export const RELEASE_TYPE_PATCH = "Patch";

export const BILLING_STATUS_ACTIVE = "Active";
export const BILLING_STATUS_DISABLED = "Disabled";

export const CHARGE_STATUS_PENDING = "Pending";
export const CHARGE_STATUS_SUCCESS = "Success";
export const CHARGE_STATUS_FAILURE = "Failure";

export const CHARGE_IMMEDIATE = "Immediate";
export const CHARGE_ONCE = "Once";
export const CHARGE_RECURRING = "Recurring";

export const CHARGES = [
    CHARGE_IMMEDIATE,
    CHARGE_ONCE,
    CHARGE_RECURRING,
];

export const CATEGORY_STATUS_ACTIVE = "Active";
export const CATEGORY_STATUS_DRAFT = "Draft";

export const USERS_NEW = "New";
export const USERS_RETURNING = "Returning";

export const USERS_AGGREGATES = [
    USERS_NEW,
    USERS_RETURNING,
];

export const ADD_NEW = "Add new...";

export const FIELD_FIRST_NAME = "firstName";
export const FIELD_LAST_NAME = "lastName";
export const FIELD_ALIAS = "alias";
export const FIELD_EMAIL = "email";
export const FIELD_PHONE = "phone";
export const FIELD_IMAGE_URL = "imageUrl";
export const FIELD_BIRTHDAY = "birthday";
export const FIELD_ROLES = "roles";

export const FIELDS = [
    FIELD_FIRST_NAME,
    FIELD_LAST_NAME,
    FIELD_ALIAS,
    FIELD_EMAIL,
    FIELD_PHONE,
    FIELD_IMAGE_URL,
    FIELD_BIRTHDAY,
    FIELD_ROLES,
];

export const ROLE_APPS = "ROLE_APPS";
export const ROLE_ACCOUNTS = "ROLE_ACCOUNTS";
export const ROLE_USERS = "ROLE_USERS";
export const ROLE_ROBOTS = "ROLE_ROBOTS";
export const ROLE_BILLING = "ROLE_BILLING";
export const ROLE_DATA_MIGRATION = "ROLE_DATA_MIGRATION";
export const ROLE_ADS = "ROLE_ADS";
export const ROLE_REVEAL = "ROLE_REVEAL";

export const APP_LOGO_DEFAULT = "https://res.cloudinary.com/cuteandfuzzy/image/upload/v1657155911/apps/tcnf0cik2dw1dcu6iwt4.png";

export const CLOUDINARY_ID = "cuteandfuzzy";

export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyA9Dj9SL_KXAQw4DfB-66XmJvyAUytOMdo",
    authDomain: "cf-notifier.firebaseapp.com",
    projectId: "cf-notifier",
    storageBucket: "cf-notifier.appspot.com",
    messagingSenderId: "741330969895",
    appId: "1:741330969895:web:795f316c4aca845967f502",
    measurementId: "G-7YK8FMDCTM"
};

export const PAGE_LOGIN = "/login";
export const PAGE_RECOVERY = "/recovery";
export const PAGE_WELCOME = "/welcome";

export const PAGE_APPS = "/apps";
export const PAGE_BILLINGS = "/billings";
export const PAGE_ACCOUNTS = "/accounts";
export const PAGE_USERS = "/users";
export const PAGE_ROBOTS = "/robots";
export const PAGE_ADS = "/ads";
export const PAGE_CATEGORIES = "/categories";
export const PAGE_OAUTH = "/oauth";
export const PAGE_APP = "/app";
export const PAGE_ACCOUNT = "/account";
export const PAGE_USER = "/user";
export const PAGE_BILLING = "/billing";
export const PAGE_CATEGORY = "/category";
export const PAGE_AGGREGATES = "/aggregates";

export const PAGE_PROFILE = "/profile";
export const PAGE_OPTIONS = "/options";
export const PAGE_PASSWORD = "/password";
export const PAGE_ABOUT = "/about";

export const PAGE_HOME = "/home";

export const ROLE_TO_HOME_PAGE = {
    apps: PAGE_APPS,
    billing: PAGE_BILLINGS,
    accounts: PAGE_ACCOUNTS,
    users: PAGE_USERS,
};

export const PAGES_MAIN = [
    PAGE_APPS,
    PAGE_BILLINGS,
    PAGE_ACCOUNTS,
    PAGE_USERS,
];

export const PAGES_MORE = [
    PAGE_ROBOTS,
    PAGE_ADS,
    PAGE_CATEGORIES,
    PAGE_OAUTH,
    PAGE_AGGREGATES,
    PAGE_PROFILE,
    PAGE_OPTIONS,
    PAGE_PASSWORD,
    PAGE_ABOUT,
];

export const PRIVATE_PAGES_EXACT = [].concat(PAGES_MAIN).concat(PAGES_MORE);

export const PRIVATE_PAGES_STARTS_WITH = [
    PAGE_APP,
    PAGE_ACCOUNT,
    PAGE_USER,
    PAGE_BILLING,
    PAGE_CATEGORY,
];

export const PUBLIC_PAGES_EXACT = [
    PAGE_LOGIN,
    PAGE_RECOVERY,
    PAGE_WELCOME,
];

export const PUBLIC_PAGES_STARTS_WITH = [];
